/*
 * @Descripttion: 
 * @version: 
 * @Author: madi
 * @Date: 2020-06-19 10:42:35
 * @LastEditors: madi
 * @LastEditTime: 2021-11-17 11:25:28
 */

// API地址
const Base_Url = process.env.NODE_ENV == 'production' ? 'https://api.gohipet.com/' : 'https://api.gohipet.com/';

// 页脚
$("#email-icon-rgt").mouseenter(() => {
    $(".email-address").toggleClass("active")
})
$("#email-icon-rgt").mouseleave(() => {
    $(".email-address").toggleClass("active")
})
$("#wx-icon-rgt").mouseenter(() => {
    $(".connect-way").toggleClass("active")
})
$("#wx-icon-rgt").mouseleave(() => {
    $(".connect-way").toggleClass("active")
})

export default {
    toggleDownBox: (event) => {
        event.stopPropagation();
        $(".drop-down-box").toggleClass("active")
        $(".introduction-item").toggleClass("active")
    },
    togglePageDownBox: (event) => {
        event.stopPropagation();
        $(".page-drop-down-box").toggleClass("active")
        $(".introduction-item").toggleClass("active")
    },
    closeAllMask: (event) => {
        event.stopPropagation();
        $(".drop-down-box").removeClass("active")
        $(".introduction-item").removeClass("active")
        $(".page-drop-down-box").removeClass("active")
    },
    scrollListener: () => {
        if(document.documentElement.scrollTop) {
            if(document.documentElement.scrollTop > 20) {
                $(".nav-wrapper").addClass("active")
            } else {
                $(".nav-wrapper").removeClass("active")
            }
        } else if(document.body.scrollTop) {
            if(document.body.scrollTop > 20) {
                $(".nav-wrapper").addClass("active")
            } else {
                $(".nav-wrapper").removeClass("active")
            }
        }
    },
    setSessionStorage: (name, item) => {
        const storage = window.sessionStorage;
        storage.setItem(name, item);
    },
    getSessionStorage: (name) => {
        const storage = window.sessionStorage;
        return storage.getItem(name)
    },
    request(url, type, data) {
        let phoneReg = /^1\d{10}$/
        if(!phoneReg.test(data.mobile)) {
            alert("请您输入正确的手机号")
            return
        }
        // return
        $.ajax({
            url: `${Base_Url}${url}`,
            type: type || post,
            data: JSON.stringify(data) || {},
            dataType: "json",
            contentType:'application/json',
            async: true,
            success: (res) => {
                window.location.href = "http://mall.gohipet.com/#/login";
            },
            error: (err) => {
                console.log(err)
                alert("网络请求错误")
            }
        })
    }
}
