/*
 * @Descripttion: 
 * @version: 
 * @Author: madi
 * @Date: 2020-06-03 17:59:26
 * @LastEditors: madi
 * @LastEditTime: 2021-11-17 11:27:26
 */
import '../css/base.less';
import '../css/index.less';
import '../css/common.less';
import commonObj from '../js/common';
import 'swiper/css/swiper.min.css';
import Swiper from 'swiper';

$(".intro-item-title").on("click", commonObj.toggleDownBox)
$(".right-icon").on("click", commonObj.togglePageDownBox)

// 跳转下一屏
$(".icon-box").on("click", () => {
    // ie
    $('body').animate({ scrollTop: document.documentElement.clientHeight }, 500);
    // 非ie
    $('html').animate({ scrollTop: document.documentElement.clientHeight }, 500);
})

window.addEventListener("load", (e) => {
    $("#home > a").addClass("v-active")
    $("#home > .page-title").addClass("v-active")
    $("#home > .left-line").addClass("v-active")
})

// 关闭全部页面弹框
$(document).on("click", () => {
    commonObj.closeAllMask(event)
})

// 页面滚动效果
$(window).scroll(commonObj.scrollListener)

// 4+版本
var mySwiper = new Swiper('.swiper-container',{
    speed: 700,
    slidesPerView : 2,
    initialSlide: 1,
    centeredSlides : true,
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
})

// 请求接口存储用户号码
$("#footer-rgt-btn").on("click", (e) => {
    e.stopPropagation()
    commonObj.request(
        'common/operation/apply',
        'post',
        {
            mobile: $("#footer-inp").val(),
            type: "1",
        }
    )
})

$("#page-rgt-btn").on("click", (e) => {
    e.stopPropagation()
    commonObj.request(
        'common/operation/apply',
        'post',
        {
            mobile: $("#page-rgt-inp").val(),
            type: "1",
        }
    )
})

// 判断客户端类型 移动端的话重新跳转
!function () { 
    // console.log(window.location)
    for (var n = window.navigator.userAgent, i = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"], o = true, e = 0, r = i.length; e < r; e++) {
        if (0 <= n.indexOf(i[e])) { 
            o = false; 
            break 
        }
    }
    if (!o) {
        // TODO: 重定向的代码
        // 1.替换域名或者文件夹路径名
        // var w = window.location.href.replace("www/", "m/");
        // 2.正则匹配以/开头 .结尾的字符串（就是html文件名），如index替换成m-index，匹配不到默认跳首页
        // var w = window.location.href.replace("www/", "m/"); 
            
        // window.location.href = w 
    } 
}()

// if(userAgent === "APP"){
//     // 跳转bai或什么都不做 - APP - 必须du写在移动端前
// } else if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
//     //   跳转或什么都不做 - 是移动
// } else {
//     //   跳转或什么都不做 - 是PC
// }